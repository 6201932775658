<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
      </Section>
      <Section limiter>
        <Row justify="between">
          <AccountMenu v-if="$laptop"
                       class="grid-md-4 grid-lg-4"/>
          <AccountContent class="grid-sm-full grid-md-8 grid-lg-8"
                          :params="{transparent: true}">
            <Row class="account-content__places-header m-b-40"
                 justify="between">
              <h5 class="account-content__places-title">Мои объекты</h5>
              <Button v-if="$laptop"
                      color="main"
                      v-on:click.native="$openPopupAddObject">Добавить объект
              </Button>
            </Row>
            <transition name="fade"
                        mode="out-in">
              <Loader v-if="!ready" :params="{style: {height: '400px'}}"/>
              <Row v-else
                   justify="between"
                   wrap>
                <PopularRouteEdit :key="item.id"
                                  :params="item"
                                  :object_type="'place'"
                                  class="grid-md-4 grid-lg-4"
                                  v-for="item in $my.places"/>
              </Row>
            </transition>
          </AccountContent>
        </Row>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n';

export default {
  name: 'AccountPlacesView',
  data() {
    return {
      ready: false,
      navigation: [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/account',
        title: 'Личный кабинет',
      }],
    };
  },
  created() {
    this.$http.get(`/api/places?user=current&count=100500&lang=${i18n.locale}`)
      .then((resolve) => {
        this.$store.commit('SET_MY_PLACES', resolve.data.data);
        this.ready = true;
      });
  },
};
</script>
